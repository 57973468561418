import * as VP from "@opr-finance/api-definitions";

import { IncreaseLimitApplication } from "./types";

export function mapApplicationToLfpRequest(
    data: IncreaseLimitApplication
): VP.components["schemas"]["ApplicationV8PostRequest"] {
    const application: VP.components["schemas"]["ApplicationV8PostRequest"] = {
        appliedProductId: process.env.REACT_APP_PRODUCT_ID as string,
        applicationChannel: "WEB",
        applicationCategory: "TOP_UP",
        smeId: data.smeId,
        notificationEmail: data.email,
        notificationMobileNumber: data.phone,
        desiredAmount: parseInt(data.newCreditLimit.replace(/\s/g, "")),
        extras: {
            turnover: data.monthlyIncomeGross,
            creditCheck: data.allowInspection ? "yes" : "no",
            consentGiven: data.personalInfoConsent ? "yes" : "no",
            checkPEPlistConsent: data.checkPEPlistConsent ? "yes" : "no",
            campaignCode: data.campaignCode,
        },
        guarantees: [
            {
                guaranteeAmount: 1,
                guarantor: {
                    guarantorType: "INDIVIDUAL_GUARANTOR",
                    reference: data.reference,
                    referenceType: "SSN",
                    givenName: data.givenName,
                    surname: data.surName,
                    birthDate: data.birthDate,
                    email: data.email,
                    //broker proxy uses mobilePhone for guarantors
                    mobilePhone: data.phone,
                },
            },
        ],
    };

    return application;
}
